// Website related settings
const settings = {
  isSplash: false,
};

//SEO Related settings
const seo = {
  title: "Christophe NORET | Portfolio",
  description:
    "Passionate about data, I design and deploy end-to-end solutions that transform raw information into actionable insights. I thrive at the intersection of data engineering, MLOps, and deployment, ensuring scalable and efficient systems in production. I also leverage analytical skills to extract meaningful insights from data and support data-driven decision-making.",
  og: {
    title: "Christophe NORET | Portfolio",
    type: "website",
    url: "https://christophenoret.com/",
  },
};

//Home Page
const greeting = {
  title: "Christophe Noret",
  logo_name: "ChristopheNoret ",
  nickname: "Data Engineering | MLOps | Analytics",
  subTitle:
    "Passionate about data, I build and deploy scalable solutions that transform raw data into actionable insights. I specialize in data engineering, MLOps, and analytics, bridging the gap between data and production-ready systems.",
  resumeLink: "",
  portfolio_repository: "https://github.com/cnoret/cnoret.github.io",
  githubProfile: "https://github.com/cnoret",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/cnoret",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/christophenoret",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  /*{
    name: "YouTube",
    link: "https://youtube.com/c/",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },*/
  {
    name: "Email",
    link: "mailto:christophe.noret@gmail.com",
    fontAwesomeIcon: "fa-at", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#6D4AFF", // Reference https://simpleicons.org/?q=gmail
  },
  /*{
    name: "X-Twitter",
    link: "https://twitter.com/",
    fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  },*/
  /*{
    name: "Facebook",
    link: "https://www.facebook.com/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },*/
  /*  name: "Instagram",
    link: "https://www.instagram.com/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },*/
];

const skills = {
  data: [
    {
      "title": "Data Engineering & MLOps",
      "fileName": "ProgrammingImg",
      "skills": [
        "⚡ Building scalable data pipelines for ingestion, transformation, and storage",
        "⚡ Deploying and monitoring machine learning models in production environments",
        "⚡ Automating workflows with Apache Airflow",
        "⚡ Implementing ML lifecycle management with MLflow",
        "⚡ Optimizing data workflows for performance and scalability"
      ],
      "softwareSkills": [
        {
          "skillName": "Python",
          "fontAwesomeClassname": "ion-logo-python",
          "style": {
            "backgroundColor": "transparent",
            "color": "#3776AB"
          }
        },
        {
          "skillName": "MLflow",
          "fontAwesomeClassname": "simple-icons:mlflow",
          "style": {
            "backgroundColor": "transparent",
            "color": "#0194E2"
          }
        },
        {
          "skillName": "Apache Airflow",
          "fontAwesomeClassname": "simple-icons:apacheairflow",
          "style": {
            "backgroundColor": "transparent",
            "color": "#017CEE"
          }
        },

      ]
    },
    {
      "title": "Cloud & Data Infrastructure",
      "fileName": "CloudInfraImg",
      "skills": [
        "⚡ Experience working with cloud platforms (AWS, Azure)",
        "⚡ Deploying and managing containerized applications using Docker & Kubernetes",
        "⚡ Hosting and maintaining databases and data warehouses",
        "⚡ Setting up CI/CD pipelines for data workflows",
        "⚡ Infrastructure as Code (IaC) with Terraform"
      ],
      "softwareSkills": [
        {
          "skillName": "AWS",
          "fontAwesomeClassname": "simple-icons:amazonaws",
          "style": {
            "color": "#FF9900"
          }
        },
        {
          "skillName": "PostgreSQL",
          "fontAwesomeClassname": "simple-icons:postgresql",
          "style": {
            "color": "#336791"
          }
        },
        {
          "skillName": "Docker",
          "fontAwesomeClassname": "simple-icons:docker",
          "style": {
            "color": "#1488C6"
          }
        },
        {
          "skillName": "Kubernetes",
          "fontAwesomeClassname": "simple-icons:kubernetes",
          "style": {
            "color": "#326CE5"
          }
        },
        {
          "skillName": "Terraform",
          "fontAwesomeClassname": "simple-icons:terraform",
          "style": {
            "color": "#326CE5"
          }
        },
      ]
    },
    /*{
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
      ],
    },*/
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    /*{
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/layman_brother/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/ashutosh_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@ashutosh391",
    },*/
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/christophenoret",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Jedha - Data Science & Engineering School",
      subtitle: 'Certified "AI Architect" (RNCP Level 7 - Master’s Equivalent)',
      logo_path: "jedha.png",
      alt_name: "Jedha Bootcamp",
      duration: "2025",
      descriptions: [
        "⚡ Advanced training in Data Engineering, MLOps, and Cloud Architecture, designed to build scalable, production-ready data infrastructures for modern organizations.",
        "⚡ Mastered ETL & ELT processes, workflow automation, and distributed computing, leveraging Apache Airflow, Spark and Kubernetes.",
        "⚡ Containerization & Deployment: Standardized environments with Docker and orchestrated workloads with Kubernetes to manage scalable machine learning models in production.",
        "⚡ Machine Learning Operations (MLOps): Implemented ML model deployment, versioning, and monitoring with MLflow, TensorFlow Serving, and FastAPI for real-time inference.",
        "⚡ Big Data & Cloud Computing: Built and optimized data pipelines and architectures using AWS, integrating data governance and management best practices.",
        "⚡ Reinforcement Learning & Advanced AI: Developed Deep Reinforcement Learning models and leveraged graph databases to analyze complex networks.",
      ],
      website_link: "https://www.jedha.co/",
    },
    {
      title: "Jedha - Data Science & Engineering School",
      subtitle:
        'Certified "Machine Learning Engineer" (RNCP Level 6 - Bachelor’s Equivalent)',
      logo_path: "jedha.png",
      alt_name: "Jedha Bootcamp",
      duration: "2024 - 2025",
      descriptions: [
        "⚡ Comprehensive training in Data Science and Machine Learning, designed to cover the full data lifecycle, from data collection to production deployment.",
        "⚡ Python & SQL Mastery: Built expertise in Python programming, SQL querying, and data manipulation, enabling efficient data processing and analysis.",
        "⚡ Web Scraping & Data Collection: Built automated pipelines to extract and process web data using Scrapy & BeautifulSoup.",
        "⚡ Model Deployment & MLOps: Deployed ML models on AWS & HuggingFace, integrated MLflow for tracking & model versioning, and created custom APIs using StreamLit & FastAPI.",
        "⚡ Production-Ready Applications: Designed end-to-end AI applications, making machine learning accessible via web interfaces.",
      ],
      website_link: "https://www.jedha.co/",
    },
    {
      title: "DataScientest x Mines Paris - PSL",
      subtitle:
        "Advanced Data Analyst Certification (Mines Paris - PSL Co-Certified)",
      logo_path: "mines.png",
      alt_name: "Datascientest.com",
      duration: "2023",
      descriptions: [
        "⚡ Data Analysis & Visualization: Mastered Python, Pandas, and SQL to analyze datasets and built interactive dashboards with Power BI, Tableau, and Looker Studio.",
        "⚡ Machine Learning & EDA: Applied classification, regression, clustering, and dimensionality reduction for data-driven insights.",
        "⚡ Web Scraping & Text Mining: Extracted and processed data using Scrapy, BeautifulSoup, and NLP techniques.",
        "⚡ Big Data & Business Intelligence: Designed data models, ETL pipelines, and optimized queries for large-scale datasets.",
        "⚡ Co-certified by Mines Paris - PSL, one of France’s top engineering schools.",
      ],
      website_link: "https://datascientest.com/",
    },
  ],
};

const certifications = {
  certifications: [
    /*{
      title: "Data Engineering Professional",
      subtitle: "Deep Learning.AI",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.deeplearning.ai/courses/data-engineering/",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },*/
    {
      title: "AI Essentials",
      subtitle: "Google",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/KBB6Y0KIPRMG",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "Data Analyst Professional",
      subtitle: "IBM",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/professional-cert/8NWVGVL8T3VZ",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    /*{
      title: "Azure",
      subtitle: "- Kim Akers",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
      alt_name: "Microsoft",
      color_code: "#D83B0199",
    },*/
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "From Software Studies to Data Engineering",
  description:
    "After two years of studying software development, I took a different path and spent 8 years in editorial retail as a sales advisor. Passionate about technology, I decided to return to the tech world and transition into Data Science & Engineering.I am now building expertise in Big Data processing, MLOps, and AI deployment, specializing in Python, SQL, Spark, Airflow, and Cloud infrastructures (AWS, Azure). My experience in business and data-driven decision-making allows me to bridge the gap between technical solutions and real-world applications.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Machine Learning Engineer",
          company: "TikTok Inc.",
          company_url: "https://www.tiktok.com/en/",
          logo_path: "tiktok_logo.png",
          duration: "June 2023 - Present",
          location: "San Jose, CA, USA",
          description:
            "Improving ads ranking models on the core TikTok product. Experience working on modeling two-tower architectures like DeepFM, Wide & deep learning, etc. Working on Large Language Models (LLM) pretraining and Large Multi-modal Model (LMM) finetuning strategies.",
          color: "#000000",
        },
        {
          title: "Associate AI Engineer",
          company: "Legato Health Technology",
          company_url: "https://legatohealthtech.com/",
          logo_path: "legato_logo.png",
          duration: "June 2020 - Aug 2021",
          location: "Hyderabad, Telangana",
          description:
            "I am working on automating healthcare products. The projects involve automation for process improvements and for significantly enhancing the profits. I am currently working on Cancer Survival and Reoccurence Prediction. Our goal is to make AI system which scales and removes doctor dependency as much as possible.",
          color: "#0879bf",
        },
        {
          title: "Android Developer",
          company: "FreeCopy Pvt. Ltd.",
          company_url: "https://www.linkedin.com/company/freecopy/about/",
          logo_path: "freecopy_logo.png",
          duration: "Nov 2017 - Dec 2017",
          location: "Ahmedabad, Gujarat",
          description:
            "FreeCopy is the Start up from Indian Institute of Management, Ahmedabad. I have changed the integration of the whole app from Google to Firebase. I learnt the efﬁcient ways of Data communications like Retroﬁt, Eventbus etc. I experienced the real time start up. I learnt the Design thinking of UI on perspective of People.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Machine Learning Intern",
          company: "TikTok Inc.",
          company_url: "https://www.tiktok.com/en/",
          logo_path: "tiktok_logo.png",
          duration: "May 2022 - Aug 2022",
          location: "San Francisco, USA",
          description:
            "Building new features on the backend recommendation system, specifically ranking algorithms for Ads that touch hundreds of millions of people around the world. Improving online and offline content ranking algorithms by performing hard sample data replays for training steps.",
          color: "#000000",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "neuro-symbolic-sudoku-solver",
      name: "Neuro-Symbolic Sudoku Solver",
      createdAt: "2023-07-02T00:00:00Z",
      description: "Paper published in KDD KiML 2023",
      url: "https://arxiv.org/abs/2307.00653",
    },
    {
      id: "mdp-diffusion",
      name: "MDP-Diffusion",
      createdAt: "2023-09-19T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/mdp-diffusion/",
    },
    {
      id: "consistency-models",
      name: "Consistency Models",
      createdAt: "2023-10-12T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/consistency-models/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "animated_ashutosh.png",
    description:
      "You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, Cloud and Opensource Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
    link: "#",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Angers, France",
    locality: "Angers",
    country: "FR",
    region: "Pays de la Loire",
    postalCode: "49000",
    streetAddress: "",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/UxVND4CqsHYxc395A",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
